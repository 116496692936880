import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/ivy-simpson.jpeg";
  const fullName = "Ivy Simpson";
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const zoomMeetingPasscode = "";
  const birthDate = "April 20, 1929";
  const deathDate = "December 25, 2020";
  const memorialDate = "Thursday December 31st, 2020";
  const memorialTime = "1pm EST";
  const inviteText = "";
  const obituary = ``;
  const storiesRecordUrl = "https://stories.afterword.com/fjxlna5o8";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/ri2obiq5aukqglqux926osk102b1wu29hjpv42oi";
  const recordingEmbedDirectUrl =
    "https://storage.googleapis.com/afterword-static/recordings/IvySimpson720.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="2fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
    ></StoryPage>
  );
};

export default ThisPage;
